var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v("Logs")]),_c('v-spacer'),_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"outlined":"","hide-details":"","flat":"","dense":"","label":_vm.lang.search},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.logs.items,"headers":_vm.headers,"no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.noResults,"hide-default-footer":"","disable-filtering":"","disable-sort":""},on:{"click:row":_vm.openLog},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.subject",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.log",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":"","outlined":""}},[(item.createdBy.name)?[_vm._v(" "+_vm._s(item.createdBy.email)+" ")]:_vm._e()],2)]}},{key:"item.log",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"line-clamp-2 my-1 mx-2"},[_vm._v(" "+_vm._s(item.log)+" ")])]}}])})],1),_c('div',{staticClass:"px-6"},[_c('v-progress-linear',{attrs:{"color":"grey","indeterminate":_vm.loading}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":15},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.log.id)?[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v("Log")])])],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.log.subject)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.log.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.log.log)+" ")])],1)],1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }