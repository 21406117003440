import ConstantTool from "@/services/tool/ConstantTool"
import Response from "@/models/responses/Response"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import JsonTool from "@/services/tool/JsonTool"
import Log from "@/models/Log"
import axios from "axios"

export default class LogService {
    
    static async findLogs(page: number, size: number, search: Nullable<string>, synchroId: Nullable<number>): Promise<Response<Log[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/log", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search, synchroId }
            })
            let logs = JsonTool.jsonConvert.deserializeArray(response.data, Log)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: logs, xTotalCount  })
        } catch (e) { return Promise.reject(e) }
    }
    
}