












































































































































import {Vue, Component, Mixins, Watch} from 'vue-property-decorator'
import PaginationMixin from "@/mixins/PaginationMixin"
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Log from "@/models/Log";
import Handler from "@/handlers/Handler";
import LogService from "@/services/LogService";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component
export default class LogsView extends Mixins(PaginationMixin) {

    lang: any = getModule(LangModule).lang
    loading: boolean = false
    logs: MultipleItem<Log> = { items: [], totalItems: 0 }
    log: Log = new Log()
    size = 10
    dialog: boolean = false

    headers = [
        {align: "center", text: this.lang.date, value: "createdAt", width: "170px"},
        {align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto"},
        {align: "center", text: this.lang.status, value: "type", width: "auto"},
        {align: "center", text: this.lang.message, value: "subject", width: "auto"},
        {align: "center", text: "Contexto", value: "log", width: "350px"},

    ]

    created() { this.refresh() }

    async refresh() {
        await Handler.getItems(this, this.logs, () =>
            LogService.findLogs(this.page - 1, this.size, this.search, null)
        )
        this.setPageCount(this.logs.totalItems!!)
    }

    openLog(log: Log) {
        this.log = log
        this.dialog = true
    }

    @Watch("page")
    onPageChanged() { this.refresh() }

}
